import { debounce } from 'lodash';
import typeahead from 'typeahead';
import 'typeahead/style.css';
import HealthClient from '../../../assets/clients/HealthClient';
import { MapView } from '../../../assets/lib/mapping/fsa/views';

const FacilityClient = new HealthClient('');

let App;

const client = new HealthClient('/reports');
const $container = $('#fsa-map-container');

if ($container.length) {
  const isNumber = n => {
    return typeof parseInt(n) === 'number' && !isNaN(n);
  };

  const $autocompleteSingleHospital = $container.find(
    'input.autocomplete-single-hospital'
  );
  let getAPIResults = (term, result) => {
    FacilityClient.get('facility_search', { term }).then(items => {
      result(items.map(item => item.label));
    });
  };

  if ($autocompleteSingleHospital.length) {
    typeahead($autocompleteSingleHospital[0], {
      minLength: 3,
      source: debounce(getAPIResults, 300),
      updater: label => {
        const match = label.match(/\d{6}/);
        if (match.length > 0) {
          return match[0];
        }
        return null;
      }
    });
  }

  const data = $container.data();

  let include_postcodes;
  let upin = data.upin;
  const rid = data.rid || '';
  const fsa_id = data.fsaId;
  const token = data.token;
  const accessToken = data.publicToken;
  const primary_threshold = data.primaryThreshold;
  const secondary_threshold = data.secondaryThreshold;

  $('#id_upin').focus();
  if (fsa_id === 'None') {
    $('#upin_submit').on('click', function(event) {
      var validUpin = true;
      $('#loading_indicator').removeClass('hide');
      $('#loading_indicator').fadeIn();

      if (
        isNumber($autocompleteSingleHospital.val()) &&
        $autocompleteSingleHospital.val().length === 6
      ) {
        // User ignored typeahead and filled out the form manually
        upin = $autocompleteSingleHospital.val();
      }

      $.get(data.url, { upin }, function(results) {
        if (results.error) {
          validUpin = false;
        }

        if (validUpin) {
          include_postcodes = $('#id_include_postcodes').is(':checked');
          App = new MapView({
            upin,
            rid,
            accessToken,
            primary_threshold,
            secondary_threshold,
            include_postcodes,
            mode: 'create'
          });
        } else {
          $('#loading_indicator').fadeOut();
          $('#loading_indicator').addClass('hide');
          $('#id_upin')
            .popover({
              content: results.message
            })
            .popover('show');
        }
      });

      event.preventDefault();
    });

    if (upin !== 'None') {
      $('#id_upin')
        .val(upin)
        .trigger('chosen:updated');
      $('#search_form').trigger('submit');
    }

    $('#id_upin').on('focus', function() {
      $(this).popover('destroy');
    });
  } else {
    $('#loading_indicator').fadeIn();

    App = new MapView({
      mode: 'edit',
      upin,
      rid,
      fsa_id,
      token,
      accessToken
    });

    $('#id_name').val(data.fsaName);
  }

  var rebuild_collections_callback = function() {
    $('#map2_overlay, #table_overlay').fadeOut();
    App.map.removeLayer(App.service_areas['primary']);
    App.map.removeLayer(App.service_areas['secondary']);
    App.setupFeatures();
  };

  $('#reset_service_areas').on('click', function(e) {
    var ok = confirm('Are you sure you want to reset your changes?');

    if (ok) {
      $('#map2_overlay, #table_overlay').fadeIn();
      App.collection.rebuild(rebuild_collections_callback);
    }
    e.preventDefault();
  });

  const verifyNumber = e => {
    const target = e.target;
    const input = target.value;
    const text_help = target.parentElement.nextElementSibling;
    const update_btn = $('#update_threshold_btn');

    if (!isNumber(input)) {
      text_help.innerText = 'Please enter a number';
      update_btn.attr('disabled', 'disabled');
    } else {
      text_help.innerText = '';
      update_btn.removeAttr('disabled');
    }
  };

  const verifySecondary = e => {
    const primary_input = $('#primary_threshold').val();
    const secondary_input = $('#secondary_threshold').val();
    if (isNumber(secondary_input) && isNumber(primary_input)) {
      const primary_number = parseFloat(primary_input);
      const secondary_number = parseFloat(secondary_input);
      let total_threshold;
      const update_btn = $('#update_threshold_btn');
      const threshold_value_text = e.target.parentElement.nextElementSibling;
      if (secondary_number <= primary_number) {
        total_threshold = primary_number + secondary_number;
      } else {
        total_threshold = secondary_input;
      }
      if (total_threshold <= data.maxThreshold) {
        threshold_value_text.innerText = '';
        update_btn.removeAttr('disabled');
      } else {
        threshold_value_text.innerText = `The combined service area cannot capture more than ${data.maxThreshold}% of the facility's market.`;
        update_btn.attr('disabled', 'disabled');
      }
    }
  };

  $('.threshold-input').keyup(verifyNumber);
  $('.threshold-input').keyup(verifySecondary);

  $('#update_threshold_btn').on('click', function(e) {
    e.preventDefault();
    App.collection.primary_threshold = $('#primary_threshold').val();
    App.collection.secondary_threshold = $('#secondary_threshold').val();
    App.collection.include_postcodes = $('#include_postcodes').is(':checked');
    $('#map2_overlay, #table_overlay').fadeIn();
    App.collection.rebuild(rebuild_collections_callback);
  });
}
