import { debounce } from 'lodash';
import typeahead from 'typeahead';
import 'typeahead/style.css';

const $autocompleteCounty = $('input.autocomplete-county');

if ($autocompleteCounty.length) {
  const clearAndFocus = evt => {
    evt.target.value = '';
    evt.target.focus();
  };

  $autocompleteCounty.on('change', clearAndFocus);

  const fieldName = $autocompleteCounty.data('field-name');
  let results = [];
  let getAPIResults = (term, result) => {
    $.getJSON('/reports/geo_autocomplete/county', { term }, items => {
      results = items;
      result(items.map(item => item.label));
    });
  };

  typeahead($autocompleteCounty[0], {
    minLength: 3,
    delay: 500,
    source: debounce(getAPIResults, 300),
    updater: label => {
      if (label) {
        for (let key = 0; key < results.length; key++) {
          const result = results[key];

          if (result.label === label) {
            $autocompleteCounty.siblings('div.selected-county').append(`
              <div class="selected-county">
                <input type="hidden" name="${fieldName}" value="${
              result.value
            }" />
                ${result.label} <a href="#" class="remove-county">Remove</a>
              </div>
            `);

            break;
          }
        }
      }

      return label;
    }
  });

  $('.selected-county').on('click', '.remove-county', function(event) {
    $(this)
      .parent('div.selected-county')
      .remove();
    event.preventDefault();
  });
}
